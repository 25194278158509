import React from 'react';
import { Outlet } from 'react-router-dom';
import MainFilter from '../components/mainfilter/MainFilter';

const SearchLayout = () => {
  return (
    <>
      <div>
        <MainFilter />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default SearchLayout;

import React from 'react';
import { Grid, Card, CardContent, Typography, Divider, TextField, InputLabel } from '@mui/material';

const ContactCard = ({ formData, handleChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h5">Contacto y redes sociales</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ paddingTop: '10px', marginBottom: '15px' }} />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InputLabel>Teléfono</InputLabel>
                  <TextField
                    name="phone"
                    variant="outlined"
                    fullWidth
                    value={formData.phone || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={8}>
                  <InputLabel>Correo electrónico</InputLabel>
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={formData.email || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Facebook</InputLabel>
                  <TextField
                    name="facebook"
                    variant="outlined"
                    fullWidth
                    value={formData.facebook || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Instagram</InputLabel>
                  <TextField
                    name="instagram"
                    variant="outlined"
                    fullWidth
                    value={formData.instagram || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Tripadvisor</InputLabel>
                  <TextField
                    name="tripadvisor"
                    variant="outlined"
                    fullWidth
                    value={formData.tripadvisor || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Whatsapp</InputLabel>
                  <TextField
                    name="whatsapp"
                    variant="outlined"
                    fullWidth
                    value={formData.whatsapp || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Sitio web</InputLabel>
                  <TextField
                    name="website"
                    variant="outlined"
                    fullWidth
                    value={formData.website || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactCard;

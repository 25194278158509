import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import AdminAuthRefresh from '../components/token/AdminAuthRefresh';
import { Provider } from 'react-redux';
import { adminStore, persistor } from '../../../stores/adminStore';
import { PersistGate } from 'redux-persist/integration/react';

const AdminLayout = () => {
  const drawerWidth = 240;

  return (
    <>
      <Provider store={adminStore}>
        <PersistGate loading={null} persistor={persistor}>
          <AdminAuthRefresh />
          <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#eef5f9' }}>
            <CssBaseline />
            <Header
              drawerWidth={drawerWidth}
              userName="Nombre del Usuario"
              onLogout={() => console.log('Cerrando sesión...')}
            />
            <Sidebar drawerWidth={drawerWidth} />
            <Box
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                mt: '64px' // Ajusta este valor si tu AppBar tiene una altura diferente
              }}>
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Outlet />
              </Box>
              <Footer />
            </Box>
          </Box>
        </PersistGate>
      </Provider>
    </>
  );
};

export default AdminLayout;

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Toolbar, Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';

const Sidebar = ({ drawerWidth }) => {
  const companyId = useSelector((state) => state.company.selectedCompany);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .05) !important'
        }
      }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <Divider sx={{ my: 2 }} />
          <ListItem button component="a" href="/route2" disabled={!companyId}>
            <ListItemText primary="Reservas" />
          </ListItem>
          <ListItem button component="a" href="/admin/escaperooms" disabled={!companyId}>
            <ListItemText primary="Escaperooms" />
          </ListItem>
          <Divider sx={{ my: 2 }} />
          <ListItem button component="a" href="/route1" disabled={!companyId}>
            <ListItemText primary="Gestión de empresa" />
          </ListItem>
          <ListItem button component="a" href="/route2" disabled={!companyId}>
            <ListItemText primary="Configuración" />
          </ListItem>
          <Divider sx={{ my: 2 }} />
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;

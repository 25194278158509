import './TabNavBar.scss';

import { getErTypes } from '../../../../services/ertypes/getErTypes';

import { React, useEffect, useState } from 'react';
import { AppBar, Container, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const TabNavBar = () => {
  const [erTypes, setErTypes] = useState([]);
  //const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const erTypes = (await getErTypes()).erTypes.map((erType) => ({
          ...erType,
          alias: erType.alias.toLowerCase() // Convertir alias a minúsculas
        }));
        setErTypes(erTypes);
      } catch (error) {
        console.error('Error fetching dictionary entries:', error);
      } finally {
        //setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <AppBar position="static" className="tabnavbar-white">
      <Container maxWidth="lg">
        <Box>
          {erTypes.map((erType, index) => (
            <Link
              key={index}
              to={`/type/${erType.alias.toLowerCase()}`}
              className={`type-filter ${
                (location.pathname === '/' && index === 0) || // Para seleccionar el primero si el pathname es "/"
                location.pathname.includes(`/type/${erType.alias.toLowerCase()}`)
                  ? 'active'
                  : ''
              }`}>
              {erType.name}
            </Link>
          ))}
        </Box>
      </Container>
    </AppBar>
  );
};

export default TabNavBar;

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="body1">
        &copy; {currentYear} Escape Masters. Todos los derechos reservados.
      </Typography>
    </Box>
  );
};

export default Footer;

// LocationCard.js
import React from 'react';
import { Grid, Card, CardContent, Typography, Divider, TextField, InputLabel } from '@mui/material';
import LocationSearch from '../../../../components/locationSearch/LocationSearch';

const LocationCard = ({ formData, handleChange }) => {
  const handleChangeLocation = (id) => {
    handleChange('locationId', id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Localización</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Población</InputLabel>
                  <LocationSearch
                    initialLocationId={formData.locationId}
                    onValueChange={handleChangeLocation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Dirección</InputLabel>
                  <TextField
                    name="address"
                    variant="outlined"
                    fullWidth
                    value={formData.address || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationCard;

// UserInfo.js
import React, { useState, useRef, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Captcha from '../../../../components/captcha/Captcha';
//import { updateUser } from '../../../../../services/users/updateUser';
import { toast } from 'sonner';
import { isValidEmail } from '../../../../../utils/emailValidator';

const UserInfo = () => {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef(null);
  //const navigate = useNavigate();

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    try {
      let correct = true;

      if (
        formData.name.length == 0 ||
        formData.surname.length == 0 ||
        formData.email.length == 0 ||
        formData.password.length == 0 ||
        formData.captchaToken.length == 0
      ) {
        correct = false;
        toast.error('Faltan campos obligatorios por informar');
      }

      if (!formData.acceptTermsAndConditions) {
        correct = false;
        toast.error('Debes aceptar las condiciones y los términos de uso');
      }

      if (!isValidEmail(formData.email)) {
        correct = false;
        toast.error('El formato del correo electrónico es incorrecto');
      }

      if (correct) {
        /*
        const result = await updateUser(formData);
        if (result.success === true) {
          toast.success('Usuario actualizado correctamente.');
          setTimeout(() => {
            navigate('/user/profile');
          }, 3000);
        } else {
          result.messages.forEach((message) => {
            toast.error(message);
          });
        }
        */
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CssBaseline />
      <Avatar sx={{ m: 1, bgcolor: (theme) => theme.palette.secondary.main, margin: 'auto' }}>
        <AccountCircleIcon />
      </Avatar>
      <Typography component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}>
        Editar Información de Usuario
      </Typography>
      <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="name"
              name="name"
              fullWidth
              id="name"
              label="Nombre"
              autoFocus
              variant="outlined"
              required
              value={formData.name || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="surname"
              label="Primer Apellido"
              name="surname"
              autoComplete="surname"
              variant="outlined"
              required
              value={formData.surname || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="lastname"
              label="Segundo Apellido"
              name="lastname"
              autoComplete="lastname"
              variant="outlined"
              value={formData.lastname || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="nickname"
              label="Apodo"
              name="nickname"
              autoComplete="nickname"
              variant="outlined"
              value={formData.nickname || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              autoComplete="email"
              variant="outlined"
              required
              value={formData.email || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="documentationType"
              label="Tipo de Documento"
              name="documentationType"
              autoComplete="documentationType"
              variant="outlined"
              value={formData.documentationType || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="documentationNumber"
              label="Número de Documento"
              name="documentationNumber"
              autoComplete="documentationNumber"
              variant="outlined"
              value={formData.documentationNumber || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"
              required
              value={formData.password || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="birthDate"
              label="Fecha de Nacimiento"
              type="date"
              id="birthDate"
              autoComplete="birth-date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData.birthDate ? formData.birthDate.toISOString().substring(0, 10) : ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.allowMarketingEmails}
                  name="allowMarketingEmails"
                  color="primary"
                />
              }
              label="Quiero recibir promociones de marketing y actualizaciones por correo electrónico."
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.acceptTermsAndConditions}
                  name="acceptTermsAndConditions"
                  color="primary"
                />
              }
              label="Acepto los términos y condiciones de uso."
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={formData.emailConfirmed} name="emailConfirmed" color="primary" />
              }
              label="Correo Electrónico Confirmado"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid sx={{ mt: 3 }}>
            <Captcha ref={captchaRef} />
          </Grid>
          <Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
              Actualizar
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="#" variant="body2">
              ¿Deseas cambiar la contraseña?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserInfo;

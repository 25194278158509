import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { thunk } from 'redux-thunk';
import mainSelectedCompanySlice from '../pages/admin/reducers/mainSelectedCompanySlice';

// Global Actions
export const resetState = () => ({
  type: 'RESET_STATE'
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage
};

// Create a persisted reducer
const companyPersistedReducer = persistReducer(persistConfig, mainSelectedCompanySlice);

// Configure store with persisted reducer
export const adminStore = configureStore({
  reducer: {
    company: companyPersistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    }).concat(thunk)
});

// Create a persistor
export const persistor = persistStore(adminStore);
export default { adminStore, persistor };

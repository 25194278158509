import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TabNavBar from '../components/tabnavbar/TabNavBar';
import AuthRefresh from '../components/token/AuthRefresh';
import { Provider } from 'react-redux';
import store from '../../../stores/store';

const MainLayout = () => {
  return (
    <>
      <Provider store={store}>
        <AuthRefresh />
        <div>
          <Header />
          <TabNavBar />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </Provider>
    </>
  );
};

export default MainLayout;

import React from 'react';
import './EscaperoomItem.scss';
import { Grid, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TruncatedText from '../../../components/text/TruncatedText';

const EscaperoomItem = ({ item }) => {
  return (
    <Grid item xs={4} sx={{ padding: '10px', position: 'relative' }}>
      <Grid
        sx={{
          position: 'absolute',
          top: '17px',
          left: '17px',
          color: '#fff',
          background: '#05203c',
          width: '45px',
          textAlign: 'center',
          borderRadius: '5px',
          paddingTop: '2px',
          paddingBottom: '2px'
        }}>
        <GroupIcon />
        <Typography variant="body1" sx={{ fontSize: '0.9rem', marginTop: '-5px' }}>
          {item.minPlayers}-{item.maxPlayers}
        </Typography>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: '70px',
          left: '17px',
          color: '#fff',
          background: '#05203c',
          width: '45px',
          textAlign: 'center',
          borderRadius: '5px',
          paddingTop: '2px',
          paddingBottom: '2px'
        }}>
        <AccessTimeFilledIcon />
        <Typography variant="body1" sx={{ fontSize: '0.9rem', marginTop: '-5px' }}>
          {item.duration}
        </Typography>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: '123px',
          left: '17px',
          color: '#fff',
          background: '#05203c',
          width: '45px',
          textAlign: 'center',
          borderRadius: '5px',
          paddingTop: '2px',
          paddingBottom: '2px'
        }}>
        <ExtensionIcon />
        <Typography variant="body1" sx={{ fontSize: '0.9rem', marginTop: '-5px' }}>
          {item.difficulty.name}
        </Typography>
      </Grid>

      <div
        className="item-image-container"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/${item.id}.png)`
        }}></div>

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            <LocationOnIcon />
            <span className="item-location-text">{item.location.city}</span>
          </Typography>
        </Grid>
        <Grid item>
          <span
            className="item-theme"
            style={{
              backgroundColor: `#${item.theme.bgColor}`,
              color: `#${item.theme.fontColor}`
            }}>
            {item.theme.name}
          </span>
        </Grid>
      </Grid>

      <Typography variant="h2" sx={{ marginBottom: '10px' }}>
        {item.title}
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: '20px', fontSize: '0.9rem' }}>
        <TruncatedText description={item.description} maxLines={3} maxCharactersPerLine={36} />
      </Typography>

      <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
        Desde {item.minPrice}€ por persona
      </Typography>

      <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '10px', color: '#ccc' }}>
        {item.company.name}
      </Typography>
    </Grid>
  );
};

export default EscaperoomItem;

import axios from 'axios';

const getErTypes = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/ertypes/get`);
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getErTypes };

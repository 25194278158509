import axios from 'axios';

const getLocationsByPartialName = async (name) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/locations/getbyname/${name}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getLocationsByPartialName };

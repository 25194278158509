import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#05203c',
        color: '#fff',
        paddingTop: '50px',
        marginTop: '50px'
      }}>
      <Container maxWidth="lg">
        {/* Sección de enlaces */}
        <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
          <Grid item xs={6} md={3}>
            <Typography variant="h6">Enlace 1</Typography>
            <Link href="/enlace-1" color="inherit" variant="body1">
              Enlace 1
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h6">Enlace 2</Typography>
            <Link href="/enlace-2" color="inherit" variant="body1">
              Enlace 2
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h6">Enlace 3</Typography>
            <Link href="/enlace-3" color="inherit" variant="body1">
              Enlace 3
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h6">Enlace 4</Typography>
            <Link href="/enlace-4" color="inherit" variant="body1">
              Enlace 4
            </Link>
          </Grid>
        </Grid>

        {/* Sección de copyright */}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Nombre de la empresa. Todos los derechos reservados.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import './NavBar.scss';
import React from 'react';
import { Box } from '@mui/system';
import { AppBar, Toolbar, Button, Container /*, Divider*/ } from '@mui/material';
//import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from './../../../../assets/images/logos/logo.png';

const NavBar = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/user/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#fff', boxShadow: 'none' }}>
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px !important'
            }}>
            <img src={logo} alt="Logo" className="logo" />
            <Box>
              {/*
              <Link href="/inicio" className="header-button-blue">
                Regala un escape room
              </Link>
              <Divider
                orientation="vertical"
                sx={{
                  display: 'inline',
                  backgroundColor: '#0062e3',
                  marginRight: '12px'
                }}
              />
              <Link href="/inicio" className="header-button-blue">
                Sorteos
              </Link>
              */}
              <Button
                sx={{
                  background: '#e0e3e5',
                  color: '#000',
                  fontWeight: 'bold',
                  textTransform: 'none'
                }}
                onClick={navigateToLogin}>
                Iniciar sesión
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default NavBar;

import { useEffect } from 'react';
import { refreshAdminTokens } from '../../../../services/authentication/refreshAdminTokens';
import { useNavigate } from 'react-router-dom';

const AdminAuthRefresh = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const accessToken = localStorage.getItem('access_admin_token');
        const refreshToken = localStorage.getItem('refresh_admin_token');

        if (!accessToken || !refreshToken) {
          navigate('/admin/login');
          return;
        }

        const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
        const oneMinute = 60; // Un minuto en segundos

        const accessTokenDecoded = JSON.parse(accessToken);
        const refreshTokenDecoded = JSON.parse(refreshToken);

        const accessTokenExpiresAt = parseInt(accessTokenDecoded.expiresAt);
        const refreshTokenExpiresAt = parseInt(refreshTokenDecoded.expiresAt);

        const accessTokenExpired = currentTime >= accessTokenExpiresAt;
        const refreshTokenExpired = currentTime >= refreshTokenExpiresAt;

        // Si accessToken expirado y refreshToken expirado o refreshToken expirado, limpiar el localStorage e ir al login
        if ((accessTokenExpired && refreshTokenExpired) || refreshTokenExpired) {
          localStorage.removeItem('access_admin_token');
          localStorage.removeItem('refresh_admin_token');
          localStorage.removeItem('admin_user_info');
          navigate('/admin/login');
          return;
        }

        if (accessTokenExpired) {
          localStorage.removeItem('access_admin_token');
          navigate('/admin/fastlogin');
          return;
        }

        // Si accessToken le queda menos de un minuto de vida o Si refreshToken le queda menos de un minuto de vida, generar un nuevo refreshToken
        if (
          accessTokenExpiresAt - currentTime < oneMinute ||
          refreshTokenExpiresAt - currentTime < oneMinute
        ) {
          const result = await refreshAdminTokens(
            JSON.parse(accessToken).token,
            JSON.parse(refreshToken).token
          );

          if (result.success) {
            if (result?.accessToken?.token?.length > 0) {
              localStorage.setItem('access_admin_token', JSON.stringify(result.accessToken));
            }
            if (result?.refreshToken?.token?.length > 0) {
              localStorage.setItem('refresh_admin_token', JSON.stringify(result.refreshToken));
            }
          } else {
            throw new Error(result.messages);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Ejecuta refreshToken inmediatamente
    refreshToken();

    // Configura el intervalo para ejecutar refreshToken cada minuto
    const intervalId = setInterval(refreshToken, 30000); // 30000 ms = 30 segundos

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default AdminAuthRefresh;

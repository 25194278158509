import React from 'react';
import NavBar from './NavBar';

const Header = () => {
  return (
    <>
      <NavBar></NavBar>
    </>
  );
};

export default Header;

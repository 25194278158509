import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { getLanguages } from '../../../../../services/languages/getLanguages';
import RichTextEditor from '../../../../components/richTextEditor/RichTextEditor';

const BasicInfoCard = ({ formData, isFormDataLoaded, handleChange, handleCheckboxChange }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [initialDescription, setInitialDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLanguages((await getLanguages()).languages);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isFormDataLoaded) {
      setInitialDescription(formData.description);

      if (formData && formData.id) {
        setImagePreviewUrl(`${process.env.REACT_APP_CDN_URL}/` + formData.id + '.png');
      }
    }
  }, [isFormDataLoaded]);

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    handleChange('description', contentState.getPlainText());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      let base64 = reader.result; // Aquí obtenemos el base64 de la imagen

      setImagePreviewUrl(base64);
      handleChange('image', base64);
    };
    reader.readAsDataURL(file);
  };

  const handleLanguageChange = (event) => {
    handleCheckboxChange(event, 'languages');
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h5">Información básica</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ paddingTop: '10px', marginBottom: '15px' }} />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel>Nombre del Escaperoom</InputLabel>
                  <TextField
                    name="title"
                    label=""
                    variant="outlined"
                    fullWidth
                    value={formData.title || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Descripción</InputLabel>
                  <RichTextEditor
                    initialText={initialDescription}
                    setEditorState={handleEditorChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <Typography variant="subtitle1">Lenguajes</Typography>
                    <FormGroup>
                      <Grid container spacing={2}>
                        {languages.map((item) => (
                          <Grid item key={item.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formData.languages.includes(String(item.id))}
                                  onChange={handleLanguageChange}
                                  value={item.id}
                                  name="languages"
                                />
                              }
                              label={item.name}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {imagePreviewUrl ? (
                    <img
                      src={imagePreviewUrl}
                      alt="Preview"
                      style={{ height: 'auto', maxHeight: '300px' }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '300px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                        border: '1px dashed #ccc'
                      }}>
                      <Typography variant="body1">Espacio reservado para la imagen</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="mainImage"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="mainImage">
                    <Button variant="outlined" component="span">
                      Upload Main Image
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicInfoCard;

// DashboardMenu.js
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const DashboardMenu = () => {
  return (
    <List>
      <ListItem
        button
        component={Link}
        to="/user/dashboard/reservations"
        sx={{
          borderTop: '1px solid #ddd'
        }}>
        <ListItemText primary="Tus reservas" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/user/dashboard/account"
        sx={{
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd'
        }}>
        <ListItemText primary="Cuenta" />
      </ListItem>
    </List>
  );
};

export default DashboardMenu;

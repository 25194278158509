const TruncatedText = ({ description, maxLines = 3, maxCharactersPerLine = 50 }) => {
  const truncateDescription = (description) => {
    const lines = description.split('\n').slice(0, maxLines);
    let truncatedDescription = lines.join('\n');
    if (truncatedDescription.length > maxLines * maxCharactersPerLine) {
      truncatedDescription =
        truncatedDescription.substring(0, maxLines * maxCharactersPerLine) + '...';
    }
    return truncatedDescription;
  };

  const truncatedDescription = truncateDescription(description);

  return truncatedDescription;
};

export default TruncatedText;

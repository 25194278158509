import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCompany: ''
};

const mainSelectedCompanySlice = createSlice({
  name: 'mainSelectedCompany',
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('RESET_STATE', () => initialState);
  }
});

export const { setSelectedCompany } = mainSelectedCompanySlice.actions;
export default mainSelectedCompanySlice.reducer;

import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Box, Button, Grid, Typography } from '@mui/material';
import Captcha from '../../../../components/captcha/Captcha';
import { toast } from 'sonner';
import { confirmEmail } from '../../../../../services/authentication/confirmEmail';

export default function EmailConfirmation() {
  const navigate = useNavigate();

  const { token } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    token: '',
    captchaToken: ''
  });
  const captchaRef = useRef(null);

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      token,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    try {
      let correct = true;
      if (!token || token.length == 0) {
        correct = false;
        toast.error('Se ha producido un error al confirmar el correo electrónico');
      }

      if (correct) {
        const result = await confirmEmail(formData);
        if (result.success == true) {
          toast.success(
            'Correo electrónico confirmado correctamente. Serás redirigido a la página de login.'
          );
          setTimeout(() => {
            navigate('/user/login');
          }, 3000);
        } else {
          result.messages.forEach((message) => {
            toast.error(message);
          });
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Confirmación de correo electrónico
        </Typography>
        <Grid>
          <Grid>
            <Captcha ref={captchaRef} />
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Confirmar correo electrónico
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

import React from 'react';
import { Navigate } from 'react-router-dom';

const LoggedAdminGuardian = ({ children }) => {
  const accessTokenInfo = JSON.parse(localStorage.getItem('access_admin_token'));
  const refreshTokenInfo = JSON.parse(localStorage.getItem('refresh_admin_token'));
  const now = new Date();

  // Look we have token, if we have it then check if is expired, otherwise grant access to the specific page
  if (
    accessTokenInfo &&
    accessTokenInfo.token.length > 0 &&
    refreshTokenInfo &&
    refreshTokenInfo.token.length > 0
  ) {
    const accessTokenExpirationDate = new Date(accessTokenInfo.expiresAt * 1000);
    if (accessTokenExpirationDate < now) {
      // Token expired, but now we have to check if renewToken is expired or not
      const refreshTokenExpirationDate = new Date(refreshTokenInfo.expiresAt * 1000);
      if (refreshTokenExpirationDate > now) {
        // The token is expired, but renew token is alive. Go to fast page Login.
        return <Navigate to="/admin/fastlogin" replace />;
      } else {
        // No token found
        localStorage.removeItem('access_admin_token');
        localStorage.removeItem('refresh_admin_token');
        localStorage.removeItem('admin_user_info');
        return <Navigate to="/admin/login" repace />;
      }
    }
  } else {
    // No token found
    localStorage.removeItem('access_admin_token');
    localStorage.removeItem('refresh_admin_token');
    localStorage.removeItem('admin_user_info');
    return <Navigate to="/admin/login" repace />;
  }

  return children;
};

export default LoggedAdminGuardian;

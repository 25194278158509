import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Button, Grid, Typography, TextField } from '@mui/material';
import Captcha from '../../../../components/captcha/Captcha';
import { toast } from 'sonner';
import { resendConfirmEmail } from '../../../../../services/authentication/resendConfirmEmail';

export default function ResendConfirmEmail() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    captchaToken: ''
  });
  const captchaRef = useRef(null);

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    try {
      let correct = true;
      if (formData.email.length == 0) {
        correct = false;
        toast.error('El correo electrónico es obligatorio.');
      }

      if (correct) {
        const result = await resendConfirmEmail(formData);
        if (result.success == true) {
          toast.success(
            'Correo electrónico enviado correctamente. Serás redirigido a la página de login.'
          );
          setTimeout(() => {
            navigate('/user/login');
          }, 3000);
        } else {
          result.messages.forEach((message) => {
            toast.error(message);
          });
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Reenviar correo de confirmación
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              variant="outlined"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Captcha ref={captchaRef} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enviar correo electrónico
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Link,
  Paper,
  Grid,
  Divider
} from '@mui/material';

const Account = () => {
  return (
    <Container component="main">
      <Box mb={4} textAlign="center">
        <Link href="/user/dashboard" variant="body2" display="block" gutterBottom>
          « Tu cuenta
        </Link>
        <Typography variant="h4" component="h1" gutterBottom>
          Cuenta
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{
            backgroundColor: '#EFF3F8',
            borderRadius: 1,
            padding: 1,
            mb: 2,
            pl: 2
          }}>
          Información general
        </Typography>
        <Paper elevation={5} sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">NIF</Typography>
              <Typography variant="body1">12345678A</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="subtitle2">Nombre y apellidos</Typography>
              <Typography variant="body1">Juan Pérez</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Fecha de nacimiento</Typography>
              <Typography variant="body1">01/01/1990</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Dirección de correo electrónico</Typography>
              <Typography variant="body1">jimenezxik91@gmail.com</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{
            backgroundColor: '#EFF3F8',
            borderRadius: 1,
            padding: 1,
            mb: 2,
            pl: 2
          }}>
          Suscripciones
        </Typography>
        <FormControlLabel
          control={<Checkbox name="subscription-checkbox" />}
          label="Me gustaría recibir las últimas ofertas, noticias y sugerencias por correo electrónico."
        />
      </Box>

      <Box mb={4}>
        <Typography
          component="h2"
          gutterBottom
          sx={{
            backgroundColor: '#EFF3F8',
            borderRadius: 1,
            padding: 1,
            mb: 2,
            pl: 2
          }}>
          Cuenta
        </Typography>
        <Paper elevation={5} sx={{ padding: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button variant="text" color="secondary">
              Eliminar cuenta
            </Button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{ width: '1rem', height: '1rem' }}>
              <path d="M8.095 4.887a1.496 1.496 0 012.008 0l5.948 6.397a1 1 0 01.003 1.358l-6.01 6.532a1.427 1.427 0 01-1.949-.138 1.572 1.572 0 01-.103-1.997l4.638-5.078-4.535-4.97a1.72 1.72 0 010-2.104z"></path>
            </svg>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Account;

import axios from 'axios';

const getLanguages = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/languages/get`);
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getLanguages };

import React from 'react';
import Slider from 'react-slick';
import { Typography, Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ title, items, template, settings }) => {
  return (
    <>
      {title && (
        <Typography variant="h1" sx={{ marginBottom: '10px' }}>
          {title}
        </Typography>
      )}
      <Slider {...settings}>
        {items.map((item, i) => (
          <div key={i}>{template(item)}</div>
        ))}
      </Slider>
      {settings.autoplay && <Box mt={1}>&nbsp;</Box>}
    </>
  );
};

export default Carousel;

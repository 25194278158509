import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Turnstile, { useTurnstile } from 'react-turnstile';

const Captcha = forwardRef((props, ref) => {
  const [token, setToken] = useState('');

  const turnstile = useTurnstile();

  const handleVerify = (newToken) => {
    setToken(newToken);
  };

  const handleExpire = () => {
    setToken('');
  };

  // Exponer el método reset al padre usando useImperativeHandle
  useImperativeHandle(ref, () => ({
    reset: () => {
      turnstile.reset();
      handleExpire();
    },
    getToken: () => token
  }));

  return (
    <Turnstile
      className="dave"
      sitekey="0x4AAAAAAAZ2gyQv7mmz1A-Z"
      onVerify={handleVerify}
      onExpire={handleExpire}
    />
  );
});

// Añadir displayName
Captcha.displayName = 'Captcha';

export default Captcha;

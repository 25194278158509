import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Typography, Grid, Button } from '@mui/material';
import { toast } from 'sonner';

import BasicInfoCard from './addEdditEscaperoomParts/BasicInfoCard';
import DetailsCard from './addEdditEscaperoomParts/DetailsCard';
import LocationCard from './addEdditEscaperoomParts/LocationCard';
import ContactCard from './addEdditEscaperoomParts/ContactCard';
import { addAdminEscaperoom } from '../../../../services/escaperooms/addAdminEscaperoom';

const AddEscaperoom = () => {
  const selectedCompany = useSelector((state) => state.company.selectedCompany);
  const [isFormDataLoaded, setIsFormDataLoaded] = useState(false);

  const [formData, setFormData] = useState({
    companyId: '',
    typeId: '',
    themeId: '',
    difficultyId: '',
    title: '',
    description: '',
    duration: '',
    minAge: '',
    playersRange: [2, 8],
    priceRange: [20, 50],
    locationId: '',
    address: '',
    email: '',
    phone: '',
    facebook: '',
    instagram: '',
    tripadvisor: '',
    whatsapp: '',
    website: '',
    languages: [],
    image: ''
  });

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event, fieldName) => {
    const { value, checked } = event.target;
    setFormData((prevFormData) => {
      const newValues = checked
        ? [...prevFormData[fieldName], value]
        : prevFormData[fieldName].filter((id) => id !== value);
      return {
        ...prevFormData,
        [fieldName]: newValues
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    let error = false;
    if (!formData.title) {
      error = true;
      toast.error('No se ha rellenado el título');
    }

    if (!formData.description) {
      error = true;
      toast.error('No se ha rellenado la descripción');
    }

    if (!formData.typeId) {
      error = true;
      toast.error('No se ha seleccionado el tipo');
    }

    if (!formData.playersRange || formData.playersRange.length != 2) {
      error = true;
      toast.error('No se ha rellenado el nº de jugadores');
    }

    if (!formData.duration) {
      error = true;
      toast.error('No se ha rellenado la duración');
    }

    if (!formData.priceRange || formData.priceRange.length != 2) {
      error = true;
      toast.error('No se ha rellenado el intervalo de precios');
    }

    if (!formData.themeId) {
      error = true;
      toast.error('No se ha informado la temática');
    }

    if (!formData.difficultyId) {
      error = true;
      toast.error('No se ha informado la difficultad');
    }

    if (!formData.locationId) {
      error = true;
      toast.error('No se ha informado de la localidad');
    }

    if (!formData.address) {
      error = true;
      toast.error('No se ha informado de la dirección');
    }

    if (!formData.website) {
      error = true;
      toast.error('No se ha informado de la página web');
    }

    if (!formData.image) {
      error = true;
      toast.error('No se ha añadido ninguna imagen');
    }

    if (formData.languages.length == 0) {
      error = true;
      toast.error('No se ha informado de los lenguajes disponibles');
    }

    if (!error) {
      try {
        const result = await addAdminEscaperoom(formData);
        if (result.success) {
          formData.image = ''; // reset the image field
          toast.success('Se ha editado el escaperoom satisfactoriamente');
        } else {
          result.messages.forEach((message) => {
            toast.error(message);
          });
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFormData((prevData) => ({
          ...prevData,
          companyId: selectedCompany
        }));

        setIsFormDataLoaded(true);
      } catch (error) {
        console.error('Error fetching escaperoom data:', error);
      }
    };

    fetchData();
  }, [selectedCompany]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom>
        New Escaperoom
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BasicInfoCard
              formData={formData}
              isFormDataLoaded={isFormDataLoaded}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailsCard formData={formData} handleChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <LocationCard formData={formData} handleChange={handleChange} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ContactCard formData={formData} handleChange={handleChange} />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '15px' }}>
          Guardar
        </Button>
      </form>
    </Container>
  );
};

export default AddEscaperoom;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { setSelectedCompany } from '../../reducers/mainSelectedCompanySlice';
import { useNavigate } from 'react-router-dom';
import { getAdminUserCompanies } from '../../../../services/users/getAdminUserCompanies';

const Header = ({ drawerWidth }) => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector((state) => state.company.selectedCompany);
  const [companies, setCompanies] = useState([]);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userJson = localStorage.getItem('admin_user_info');
    if (userJson) {
      const user = JSON.parse(userJson);
      setUsername(user.name + ' ' + user.surname);
    }

    const fetchCompanies = async () => {
      try {
        const result = await getAdminUserCompanies();
        if (result && result.success) {
          setCompanies(result.companies);
        }
      } catch (error) {
        console.error('Error recuperando las compañías:', error);
      }
    };

    fetchCompanies();
  }, []);

  const handleCompanyChange = (event) => {
    dispatch(setSelectedCompany(event.target.value));
    navigate('/admin');
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#2962FF',
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`
      }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h6" noWrap sx={{ color: 'hsla(0, 0%, 100%, 0.55)', marginRight: 2 }}>
            Empresa
          </Typography>
          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, '& .MuiOutlinedInput-notchedOutline': { border: 0 } }}>
            {companies.length > 0 && (
              <Select
                value={selectedCompany}
                onChange={handleCompanyChange}
                displayEmpty
                sx={{
                  color: '#000',
                  borderColor: 'white',
                  backgroundColor: '#fff',
                  height: '36px'
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: '#fff',
                      '& .MuiMenuItem-root': {
                        color: '#000'
                      }
                    }
                  }
                }}>
                <MenuItem value="" disabled>
                  Seleccione empresa
                </MenuItem>
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ marginRight: 2, color: 'hsla(0, 0%, 100%, 0.55)' }}>
            {username}
          </Typography>
          <Button variant="contained" color="primary">
            Cerrar sesión
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

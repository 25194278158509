// DashboardLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, Grid, useMediaQuery, Container } from '@mui/material';
import DashboardMenu from '../components/dashboard/DashboardMenu';
import DashboardUserInfo from '../components/dashboard/DashboardUserInfo';

const DashboardLayout = () => {
  const isMobile = useMediaQuery('(max-width:1024px)');

  return (
    <>
      <div>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Grid container>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <DashboardUserInfo />
                  <DashboardMenu />
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 8}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 3,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <Outlet sx={{ width: '100%' }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default DashboardLayout;

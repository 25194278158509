import axios from 'axios';

const getDictionaryEntries = async (code) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dictionaries/get/${code}`);
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getDictionaryEntries };

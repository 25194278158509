import axios from 'axios';

const getAdminUserCompanies = async () => {
  try {
    const tokenJson = localStorage.getItem('access_admin_token');
    if (tokenJson) {
      const token = JSON.parse(tokenJson);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/users/companies/get`,
        {
          headers: {
            Authorization: `Bearer ${token.token}`
          }
        }
      );
      return response.data;
    }
    return null;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getAdminUserCompanies };

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getLocationById } from '../../../services/locations/getLocationById';
import { getLocationsByPartialName } from '../../../services/locations/getLocationsByPartialName';

const LocationSearch = ({ initialLocationId, onValueChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchPoblacion = async () => {
      if (initialLocationId) {
        const response = await getLocationById(initialLocationId);
        setValue(response.location);
        setInputValue(response.location.city);
      }
    };

    fetchPoblacion();
  }, [initialLocationId]);

  useEffect(() => {
    const fetchPoblaciones = async () => {
      if (inputValue.length >= 3) {
        try {
          const response = await getLocationsByPartialName(inputValue);
          setOptions(Array.isArray(response && response.locations) ? response.locations : []);
        } catch (error) {
          console.error('Error fetching locations:', error);
          setOptions([]); // Ensure options is an array even on error
        }
      } else {
        setOptions([]); // Clear options if input value is less than 3 characters
      }
    };

    fetchPoblaciones();
  }, [inputValue]);

  useEffect(() => {
    if (onValueChange && value) {
      onValueChange(value.id);
    }
  }, [value]);

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option.city}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        setInputValue(newValue ? newValue.city : '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder='Población sin "Els", Los", "A", ... Ej: Coruña'
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
    />
  );
};

export default LocationSearch;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Captcha from '../../../../components/captcha/Captcha';
//import { loginUser } from '../../../../services/users/loginUser';
import { toast } from 'sonner';
import { isValidEmail } from '../../../../../utils/emailValidator';
import { logIn } from '../../../../../services/authentication/logInUser';

export default function FastLogIn() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    captchaToken: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    event.preventDefault();

    try {
      if (
        formData.email.length == 0 ||
        formData.password.length == 0 ||
        formData.captchaToken.length == 0
      ) {
        toast.error('Faltan campos obligatorios por informar');
        return;
      }

      if (!isValidEmail(formData.email)) {
        toast.error('El formato del correo electrónico es incorrecto');
        return;
      }

      const result = await logIn(formData);
      if (result.success) {
        // save tokens to localstorage
        localStorage.setItem('access_token', JSON.stringify(result.accessToken));
        localStorage.setItem('refresh_token', JSON.stringify(result.refreshToken));
        localStorage.setItem('userInfo', JSON.stringify(result.user));

        toast.success(
          'Inicio de sesión exitoso. En breves, serás redirigido a la página principal.'
        );
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        result.messages.forEach((message) => {
          toast.error(message);
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }

      // reset password on every submit
      setFormData({
        ...formData,
        ['password']: ''
      });
    }
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CssBaseline />
        <Avatar
          sx={{
            m: 1,
            bgcolor: (theme) => theme.palette.secondary.main,
            margin: 'auto' // Center the Avatar horizontally
          }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}>
          Iniciar sesión
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                variant="outlined"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="outlined"
                required
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Captcha ref={captchaRef} />
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
            Iniciar sesión
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/user/signup" variant="body2">
                ¿No tienes una cuenta? Regístrate
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

import axios from 'axios';

const resendConfirmEmail = async (formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/resendconfirmemail`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { resendConfirmEmail };

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Captcha from '../../../../components/captcha/Captcha';
import { addUser } from '../../../../../services/users/addUser';
import { toast } from 'sonner';
import { isValidEmail } from '../../../../../utils/emailValidator';

export default function SignUp() {
  const [formData, setFormData] = useState({
    firstname: '',
    surname: '',
    email: '',
    password: '',
    retypePassword: '',
    captchaToken: '',
    allowExtraEmails: false,
    allowTermsAndConditions: false
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    try {
      let correct = true;

      if (
        formData.firstname.length == 0 ||
        formData.surname.length == 0 ||
        formData.email.length == 0 ||
        formData.password.length == 0 ||
        formData.retypePassword.length == 0 ||
        formData.captchaToken.length == 0
      ) {
        correct = false;
        toast.error('Faltan campos obligatorios por informar');
      }

      if (!formData.allowTermsAndConditions) {
        correct = false;
        toast.error('Debes aceptar las condiciones y los términos de uso');
      }

      if (formData.password != formData.retypePassword) {
        correct = false;
        toast.error('Las contraseñas no coinciden');
      }

      if (!isValidEmail(formData.email)) {
        correct = false;
        toast.error('El formato del correo electrónico es incorrecto');
      }

      if (correct) {
        const result = await addUser(formData);
        if (result.success == true) {
          toast.success('Usuario creado correctamente. Serás redirigido a la página de login.');
          setTimeout(() => {
            navigate('/user/login');
          }, 3000);
        } else {
          result.messages.forEach((message) => {
            toast.error(message);
          });
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }
    }
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CssBaseline />
        <Avatar
          sx={{
            m: 1,
            bgcolor: (theme) => theme.palette.secondary.main,
            margin: 'auto' // Centra el Avatar horizontalmente
          }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}>
          Registro
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="firstname"
                name="firstname"
                fullWidth
                id="firstname"
                label="Nombre"
                autoFocus
                variant="outlined"
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="surname"
                label="Primer apellido"
                name="surname"
                autoComplete="surname"
                variant="outlined"
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                variant="outlined"
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="outlined"
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="retypePassword"
                label="Repite la contraseña"
                type="password"
                id="retype-password"
                autoComplete="retype-current-password"
                variant="outlined"
                required
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox value="allowExtraEmails" name="allowExtraEmails" color="primary" />
                }
                label="Quiero recibir promociones de marketing y actualizaciones por correo electrónico."
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="allowTermsAndConditions"
                    name="allowTermsAndConditions"
                    color="primary"
                  />
                }
                label="Acepto los términos y condiciones de uso."
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={{ mt: 3 }}>
              <Captcha ref={captchaRef} />
            </Grid>
            <Grid>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
                Registrar
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                ¿Ya tienes una cuenta? Iniciar sesión
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

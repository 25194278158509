import React from 'react';
import Standard from '../pages/front/pages/ercategories/Standard';
import Online from '../pages/front/pages/ercategories/Online';
import VirtualReality from '../pages/front/pages/ercategories/VirtualReality';
import SignUp from '../pages/front/pages/user/Register/SignUp';
import LogIn from '../pages/front/pages/user/Login/LogIn';
import MainLayout from '../pages/front/layouts/MainLayout';
import SearchLayout from '../pages/front/layouts/SearchLayout';
import NotLoggedGuardian from './NotLoggedGuardian';
import LoggedGuardian from './LoggedGuardian';
import FastLogIn from '../pages/front/pages/user/Login/FastLogIn';
import DashboardLayout from '../pages/front/layouts/DashboardLayout';
import UserInfo from '../pages/front/pages/user/Dashboard/UserInfo';
import Account from '../pages/front/pages/user/Dashboard/Account';
import AdminLayout from '../pages/admin/layouts/AdminLayout';
import AdminLogIn from '../pages/admin/pages/user/Login/AdminLogIn';
import Home from '../pages/admin/pages/home/Home';
import NotLoggedAdminGuardian from './NotLoggedAdminGuardian';
import LoggedAdminGuardian from './LoggedAdminGuardian';
import ListEscaperooms from '../pages/admin/pages/escaperooms/ListEscaperooms';
import EditEscaperoom from '../pages/admin/pages/escaperooms/EditEscaperoom';
import AddEscaperoom from '../pages/admin/pages/escaperooms/AddEscaperoom';
import ConfirmEmail from '../pages/front/pages/user/ConfirmEmail/ConfirmEmail';
import ResendConfirmEmail from '../pages/front/pages/user/ResendConfirmEmail/ResendConfirmEmail';

const routes = [
  {
    path: '/admin/login',
    element: (
      <NotLoggedAdminGuardian isFastLogin={false}>
        <AdminLogIn />
      </NotLoggedAdminGuardian>
    )
  },
  {
    path: '/admin/fastlogin',
    element: (
      <NotLoggedAdminGuardian isFastLogin={true}>
        <AdminLogIn />
      </NotLoggedAdminGuardian>
    )
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: '/admin',
        element: (
          <LoggedAdminGuardian>
            <Home />
          </LoggedAdminGuardian>
        )
      },
      {
        path: '/admin/escaperooms',
        element: (
          <LoggedAdminGuardian>
            <ListEscaperooms />
          </LoggedAdminGuardian>
        )
      },
      {
        path: '/admin/escaperooms/add',
        element: (
          <LoggedAdminGuardian>
            <AddEscaperoom />
          </LoggedAdminGuardian>
        )
      },
      {
        path: '/admin/escaperooms/edit/:id',
        element: (
          <LoggedAdminGuardian>
            <EditEscaperoom />
          </LoggedAdminGuardian>
        )
      }
    ]
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: '/user/signup',
        element: (
          <NotLoggedGuardian isFastLogin={false}>
            <SignUp />
          </NotLoggedGuardian>
        )
      },
      {
        path: '/user/login',
        element: (
          <NotLoggedGuardian isFastLogin={false}>
            <LogIn />
          </NotLoggedGuardian>
        )
      },
      {
        path: '/user/fastlogin',
        element: (
          <NotLoggedGuardian isFastLogin={true}>
            <FastLogIn />
          </NotLoggedGuardian>
        )
      },
      {
        path: '/user/resendconfirmemail',
        element: (
          <NotLoggedGuardian isFastLogin={false}>
            <ResendConfirmEmail />
          </NotLoggedGuardian>
        )
      },
      {
        path: '/user/confirmemail/:token',
        element: (
          <NotLoggedGuardian isFastLogin={false}>
            <ConfirmEmail />
          </NotLoggedGuardian>
        )
      },
      {
        element: <SearchLayout />,
        children: [
          { path: '/', element: <Standard /> },
          { path: '/type/standard', element: <Standard /> },
          { path: '/type/vr', element: <VirtualReality /> },
          { path: '/type/online', element: <Online /> }
        ]
      },
      {
        element: <DashboardLayout />,
        children: [
          {
            path: 'user/dashboard',
            element: (
              <LoggedGuardian>
                <Account />
              </LoggedGuardian>
            )
          },
          {
            path: 'user/dashboard/account',
            element: (
              <LoggedGuardian>
                <Account />
              </LoggedGuardian>
            )
          },
          {
            path: 'user/dashboard/userinfo',
            element: (
              <LoggedGuardian>
                <UserInfo />
              </LoggedGuardian>
            )
          }
        ]
      }
    ]
  }
];

export default routes;

import { React, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { getNewEscaperooms } from '../../../../services/escaperooms/getNewEscaperooms';
import EscaperoomItem from '../../components/escaperoom/EscaperoomItem';
import Carousel from '../../components/carousel/Carousel';

const Standard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newEscaperooms, setNewEscaperooms] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  const template = (item) => <EscaperoomItem item={item} />;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newEscapes = (await getNewEscaperooms()).escaperooms;
        setNewEscaperooms(newEscapes);
      } catch (error) {
        console.error('Error fetching NewEscaperooms data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        {!isLoading && (
          <Carousel
            title="Escaperooms nuevos"
            items={newEscaperooms}
            template={template}
            settings={settings}
          />
        )}
      </Container>
    </>
  );
};

export default Standard;

import React from 'react';
import { Navigate } from 'react-router-dom';

const NotLoggedGuardian = ({ isFastLogin, children }) => {
  const accessTokenInfo = JSON.parse(localStorage.getItem('access_token'));
  const refreshTokenInfo = JSON.parse(localStorage.getItem('refresh_token'));
  const now = new Date();

  // Look we have token, if we have it then check if is expired, otherwise grant access to the specific page
  if (
    accessTokenInfo &&
    accessTokenInfo.token.length > 0 &&
    refreshTokenInfo &&
    refreshTokenInfo.token.length > 0
  ) {
    const accessTokenExpirationDate = new Date(accessTokenInfo.expiresAt * 1000);
    if (accessTokenExpirationDate < now) {
      // Token expired, but now we have to check if renewToken is expired or not
      const refreshTokenExpirationDate = new Date(refreshTokenInfo.expiresAt * 1000);
      if (refreshTokenExpirationDate > now) {
        // Renew token is alive. Go to fast page Login.
        if (!isFastLogin) {
          return <Navigate to="/user/fastlogin" replace />;
        }
      }
    } else {
      // The token is alive and good, we can't go to the page, redirect to the main page
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

export default NotLoggedGuardian;

import './MainFilter.scss';

import { React, useEffect, useState } from 'react';
import { getDictionaryEntries } from '../../../../services/dictionaries/getDictionaries';

import { TextField, Button, Grid, Select, MenuItem, InputLabel } from '@mui/material';
import MainFilterSkeleton from './MainFilterSkeleton';
import { getDifficulties } from '../../../../services/difficulties/getDifficulties';

const MainFilter = () => {
  // Select fields
  const [players, setPlayers] = useState([]);
  const [publics, setPublics] = useState([]);
  const [difficulties, setDifficulties] = useState([]);

  const [filterValues, setFilterValues] = useState({
    address: '',
    difficulties: [],
    publics: [],
    players: []
  });

  const [isLoading, setIsLoading] = useState(true);

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    const ultimoSeleccionado = value[value.length - 1];

    const updatedValue =
      ultimoSeleccionado === 'ALL' || ultimoSeleccionado === undefined
        ? ['ALL']
        : value.filter((option) => option !== 'ALL');

    setFilterValues({
      ...filterValues,
      [name]: updatedValue
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const players = (await getDictionaryEntries('PLAYERS')).dictionary?.dictionaryEntries;
        setPlayers(players);

        const publics = (await getDictionaryEntries('PUBLICS')).dictionary?.dictionaryEntries;
        setPublics(publics);

        const difficulties = (await getDifficulties()).difficulties;
        setDifficulties(difficulties);
      } catch (error) {
        console.error('Error fetching dictionary entries:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Set All as default selected option
    setFilterValues((prevValues) => ({
      ...prevValues,
      publics: ['ALL'],
      difficulties: ['ALL'],
      players: ['ALL']
    }));
  }, []);

  return (
    <div className="filter-container">
      <div className="filter-main filter-main-bg filter-all-bg">
        <div className="filter-main-filter">
          <h1 className="filter-main-filter-title">Encuentra el Escape Room perfecto hoy mismo</h1>
          {isLoading ? (
            <MainFilterSkeleton />
          ) : (
            <div className="filter-main-filter-search">
              <form>
                <Grid container spacing={2} className="filter-container">
                  <Grid item xs={12} sm={4}>
                    <InputLabel
                      id="modalities-label"
                      className="field-label field-label-first modalities-label">
                      Lugar
                    </InputLabel>
                    <TextField label="Población o provincia" fullWidth className="input-control" />
                  </Grid>
                  <Grid item xs={12} sm={2} className="filter-grid-hide">
                    <InputLabel id="public-label" className="field-label public-label">
                      Público
                    </InputLabel>
                    <Select
                      fullWidth
                      className="input-control"
                      name="publics"
                      value={filterValues.publics}
                      onChange={handleSelectChange}
                      multiple>
                      <MenuItem key="ALL" value="ALL">
                        Todos
                      </MenuItem>

                      {publics.map((item) => (
                        <MenuItem key={item.id} value={item.alias}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={2} className="filter-grid-hide">
                    <InputLabel id="difficulty-label" className="field-label difficulty-label">
                      Dificultad
                    </InputLabel>
                    <Select
                      fullWidth
                      className="input-control"
                      name="difficulties"
                      value={filterValues.difficulties}
                      onChange={handleSelectChange}
                      multiple>
                      <MenuItem key="ALL" value="ALL">
                        Todos
                      </MenuItem>
                      {difficulties.map((item) => (
                        <MenuItem key={item.id} value={item.alias}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel id="players-label" className="field-label players-label">
                      Nº Jugadores
                    </InputLabel>
                    <Select
                      fullWidth
                      className="input-control"
                      name="players"
                      value={filterValues.players}
                      onChange={handleSelectChange}
                      multiple>
                      <MenuItem key="ALL" value="ALL">
                        Todos
                      </MenuItem>
                      {players.map((item) => (
                        <MenuItem key={item.id} value={item.alias}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="submit-control">
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </div>
      </div>

      <div className="filter-arrow-bottom">
        <svg
          viewBox="0 0 14832 55"
          xmlns="http://www.w3.org/2000/svg"
          className="filter-arrow-bottom-img">
          <path
            d="M7501.307 8.517l-68.043 39.341c-10.632 6.185-23.795 6.185-34.528 0l-68.144-39.34c-8.91-5.173-18.988-8.215-29.316-8.518H0v55h14832V0H7530.671a63.604 63.604 0 00-29.364 8.517z"
            fill="#fff"></path>
        </svg>
      </div>
    </div>
  );
};

export default MainFilter;

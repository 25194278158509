import axios from 'axios';

const editAdminEscaperoom = async (escaperoomData) => {
  try {
    const tokenJson = localStorage.getItem('access_admin_token');
    if (tokenJson) {
      const token = JSON.parse(tokenJson);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/escaperooms/edit`,
        escaperoomData,
        {
          headers: {
            Authorization: `Bearer ${token.token}`
          }
        }
      );
      return response.data;
    }
    return null;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { editAdminEscaperoom };

import axios from 'axios';

const addUser = async (formUserData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/add`, formUserData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { addUser };

// DetailsCard.js
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Slider
} from '@mui/material';
import { getDifficulties } from '../../../../../services/difficulties/getDifficulties';
import { getThemes } from '../../../../../services/themes/getThemes';
import { getErTypes } from '../../../../../services/ertypes/getErTypes';

const DetailsCard = ({ formData, handleChange }) => {
  const [difficulties, setDifficulties] = useState([]);
  const [themes, setThemes] = useState([]);
  const [types, setTypes] = useState([]);

  const numPlayersMarks = [
    { value: 1, label: '1' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
  ];

  const priceMarks = [
    { value: 1, label: '1€' },
    { value: 20, label: '20€' },
    { value: 40, label: '40€' },
    { value: 60, label: '60€' },
    { value: 80, label: '80€' },
    { value: 100, label: '100€' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDifficulties((await getDifficulties()).difficulties);
        setThemes((await getThemes()).themes);
        setTypes((await getErTypes()).erTypes);
      } catch (error) {
        console.error('Error fetching dictionary entries:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h5">Detalles</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ paddingTop: '10px', marginBottom: '15px' }} />
              </Grid>
              <Grid container spacing={2}>
                {types.length > 0 && (
                  <Grid item xs={4}>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      name="typeId"
                      value={formData.typeId || ''}
                      onChange={handleInputChange}
                      className="select-field"
                      style={{ width: '100%' }}>
                      <MenuItem value=""></MenuItem>
                      {types.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                {themes.length > 0 && (
                  <Grid item xs={4}>
                    <InputLabel>Temática</InputLabel>
                    <Select
                      name="themeId"
                      value={formData.themeId || ''}
                      onChange={handleInputChange}
                      className="select-field"
                      style={{ width: '100%' }}>
                      <MenuItem value=""></MenuItem>
                      {themes.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                {difficulties.length > 0 && (
                  <Grid item xs={4}>
                    <InputLabel>Difficulty</InputLabel>
                    <Select
                      name="difficultyId"
                      value={formData.difficultyId || ''}
                      onChange={handleInputChange}
                      className="select-field"
                      style={{ width: '100%' }}>
                      <MenuItem value=""></MenuItem>
                      {difficulties.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <InputLabel>Duración</InputLabel>
                  <TextField
                    name="duration"
                    label=""
                    variant="outlined"
                    fullWidth
                    value={formData.duration || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Edad mínima</InputLabel>
                  <TextField
                    name="minAge"
                    label=""
                    variant="outlined"
                    fullWidth
                    value={formData.minAge || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Nº de jugadores</InputLabel>
                  <Slider
                    sx={{ marginTop: '40px' }}
                    value={formData.playersRange || [2, 8]}
                    marks={numPlayersMarks}
                    onChange={(e, newValue) => handleChange('playersRange', newValue)}
                    valueLabelDisplay="on"
                    min={1}
                    max={30}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Precio</InputLabel>
                  <Slider
                    sx={{ marginTop: '40px' }}
                    value={formData.priceRange || [20, 50]}
                    marks={priceMarks}
                    onChange={(e, newValue) => handleChange('priceRange', newValue)}
                    valueLabelDisplay="on"
                    min={1}
                    max={100}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsCard;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CssBaseline, TextField, Grid, Box, Typography, Container } from '@mui/material';
import Captcha from '../../../../components/captcha/Captcha';
import { toast } from 'sonner';
import { isValidEmail } from '../../../../../utils/emailValidator';
import { logInAdmin } from '../../../../../services/authentication/logInAdminUser';
import { adminStore, persistor, resetState } from '../../../../../stores/adminStore';

export default function AdminLogIn() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    captchaToken: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSubmitting) {
      handleFormSubmit();
    }
  }, [isSubmitting]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const captchaToken = captchaRef.current.getToken();
    setFormData({
      ...formData,
      captchaToken
    });
    setIsSubmitting(true);
  };

  const handleFormSubmit = async () => {
    event.preventDefault();

    try {
      if (
        formData.email.length == 0 ||
        formData.password.length == 0 ||
        formData.captchaToken.length == 0
      ) {
        toast.error('Faltan campos obligatorios por informar');
        return;
      }

      if (!isValidEmail(formData.email)) {
        toast.error('El formato del correo electrónico es incorrecto');
        return;
      }

      const result = await logInAdmin(formData);
      if (result.success) {
        // save tokens to localstorage
        localStorage.setItem('access_admin_token', JSON.stringify(result.accessToken));
        localStorage.setItem('refresh_admin_token', JSON.stringify(result.refreshToken));
        localStorage.setItem('admin_user_info', JSON.stringify(result.user));
        persistor.purge().then(() => {
          adminStore.dispatch(resetState());
        });

        toast.success('Inicio de sesión exitoso. En breves, serás redirigido al dashboard.');
        setTimeout(() => {
          navigate('/admin');
        }, 3000);
      } else {
        result.messages.forEach((message) => {
          toast.error(message);
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
      if (captchaRef.current) {
        captchaRef.current.reset();
        setFormData({
          ...formData,
          captchaToken: ''
        });
      }

      // reset password on every submit
      setFormData({
        ...formData,
        ['password']: ''
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh' // Full height of the viewport
      }}>
      <CssBaseline />
      <Typography component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}>
        Iniciar sesión
      </Typography>
      <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              variant="outlined"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"
              required
              value={formData.password}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Captcha ref={captchaRef} />
        </Grid>
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
          Iniciar sesión
        </Button>
      </Box>
    </Container>
  );
}

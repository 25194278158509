import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

const DashboardUserInfo = () => {
  const defaultAvatarUrl = `${process.env.REACT_APP_CDN_URL}/default_avatar.jpg`;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
        <Avatar src={defaultAvatarUrl} sx={{ width: 80, height: 80 }} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Hola, Joan
        </Typography>
      </Box>
    </>
  );
};

export default DashboardUserInfo;

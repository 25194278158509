import React from 'react';
import { TextField, Button, Grid, InputLabel } from '@mui/material';

import { Skeleton } from '@mui/material';

const MainFilterSkeleton = () => {
  return (
    <div className="filter-main-filter-search">
      <form>
        <Grid container spacing={2} className="filter-container">
          <Grid item xs={12} sm={4}>
            <InputLabel id="modalities-label" className="field-label modalities-label">
              <Skeleton variant="text" width={100} />
            </InputLabel>
            <TextField
              label={<Skeleton variant="text" width="100%" />}
              fullWidth
              className="input-control"
              InputProps={{
                readOnly: true // Para evitar que el usuario escriba mientras se carga
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="public-label" className="field-label public-label">
              <Skeleton variant="text" width={100} />
            </InputLabel>
            <TextField
              label={<Skeleton variant="text" width="100%" />}
              fullWidth
              className="input-control"
              InputProps={{
                readOnly: true // Para evitar que el usuario escriba mientras se carga
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="difficulty-label" className="field-label difficulty-label">
              <Skeleton variant="text" width={100} />
            </InputLabel>
            <TextField
              label={<Skeleton variant="text" width="100%" />}
              fullWidth
              className="input-control"
              InputProps={{
                readOnly: true // Para evitar que el usuario escriba mientras se carga
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="players-label" className="field-label players-label">
              <Skeleton variant="text" width={100} />
            </InputLabel>
            <TextField
              label={<Skeleton variant="text" width="100%" />}
              fullWidth
              className="input-control"
              InputProps={{
                readOnly: true // Para evitar que el usuario escriba mientras se carga
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button variant="contained" color="primary" type="submit" className="submit-control">
              <Skeleton variant="text" width="100%" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MainFilterSkeleton;

import axios from 'axios';

const getLocationById = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/locations/get/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getLocationById };

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box
} from '@mui/material';
import { getAdminCompaniesEscaperooms } from '../../../../services/companies/getAdminCompaniesEscaperooms';
import TruncatedText from '../../../components/text/TruncatedText';
import { useNavigate } from 'react-router-dom';

const ListEscaperooms = () => {
  const navigate = useNavigate();

  const [escaperooms, setEscaperooms] = useState([]);

  // Obtener el ID de la empresa desde el estado global
  const companyId = useSelector((state) => state.company.selectedCompany);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const result = await getAdminCompaniesEscaperooms(companyId);
        if (result && result.success) {
          setEscaperooms(result.escaperooms);
        }
      } catch (error) {
        console.error('Error recuperando los escaperooms:', error);
      }
    };

    if (companyId) {
      fetchCompanies();
    }
  }, [companyId]);

  const handleEdit = (id) => {
    navigate(`/admin/escaperooms/edit/${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Delete escape room with ID: ${id}`);
    setEscaperooms(escaperooms.filter((room) => room.id !== id));
  };

  const handleAddNew = () => {
    navigate('/admin/escaperooms/add');
  };

  return (
    <Container>
      <Box display="flex" justifyContent="flex-end" padding={2}>
        <Button variant="contained" color="primary" onClick={handleAddNew}>
          Añadir nuevo
        </Button>
      </Box>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Empresa</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Tipo</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Temática</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Nombre</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Localización
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {escaperooms.map((room) => (
                <TableRow key={room.id}>
                  <TableCell>{room.company.name}</TableCell>
                  <TableCell>{room.type.name}</TableCell>
                  <TableCell>{room.theme.name}</TableCell>
                  <TableCell>{room.title}</TableCell>
                  <TableCell>
                    <TruncatedText
                      description={room.address}
                      maxLines={1}
                      maxCharactersPerLine={50}
                    />
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(room.id)}>
                      Editar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(room.id)}
                      style={{ marginLeft: 8 }}>
                      Dar de baja
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ListEscaperooms;

import axios from 'axios';

const getEscaperoomById = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/escaperooms/get/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.toString());
  }
};

export { getEscaperoomById };
